export default [
    {
        path: 'directions',
        component: () => import('@/views/dashboard/directions/Managment'),
        meta: {
            title: 'Gestión de direcciones',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de direcciones', link: '/dashboard/directions'},
            ]
        }
    },
    {
        path: 'directions/add',
        component: () => import('@/views/dashboard/directions/AddDirection'),
        meta: {
            title: 'Gestión de direcciones',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de direcciones', link: '/dashboard/directions'},
                {label: 'Nueva dirección'}
            ]
        }
    },
    {
        path: 'directions/edit',
        component: () => import('@/views/dashboard/directions/EditDirection'),
        meta: {
            title: 'Gestión de direcciones',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de direcciones', link: '/dashboard/directions'},
                {label: 'Editar dirección'}
            ]
        }
    },
]