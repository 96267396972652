import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
// Modules
import loginRouter from './modules/login.router'
import dashboardRouter from './modules/dashboard'
import customerRouter from './modules/customer'
import sharedRouter from './modules/shared/shared.router';
Vue.use(VueRouter)

const routes = [
  ...loginRouter,
  ...customerRouter,
  ...dashboardRouter,
  ...sharedRouter,
  {
    path: '/',
    redirect: '/customer',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    component: () => import('@/layouts/FullWidthLayout.vue'),
    meta: {
        requiresAuth: true
    },
    children: [
      {
        path: '',
        component: ()=>import('@/views/customer/Profile.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [
              {label: 'Inicio', link: '/'},
              {label: 'Perfil'}
          ]
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(){
    return{x: 0, y:0}
  }
})

router.beforeEach((to, from, next)=>{
  store.dispatch('session/checkSession')
  .then(() => {
    if(to.matched.some(record => record.meta.requiresAuth)){
      // Verify if user is logged
      if(store.getters['session/isAuthenticated']){ 
        // Verify if user is active
        if(store.getters['session/isActive']){
          if (to?.fullPath === '/dashboard/home' || to?.fullPath?.includes('/customer') || to?.fullPath?.includes('/profile')) {
            next(true)
          } else if (store?.state?.session?.modules) {
            const list_modules = store?.state?.session?.modules?.filter(item =>{

              for(let label of to?.meta?.breadcrumb){
                if( label.label.includes(item.name)) return true
              }
            })
            if (list_modules.length > 0 || true) {
                next(true)
            } else {
                next('/dashboard/home')
            }
            
        } else {
          // consumidor financiero
         
            next('/dashboard/home')
        }
        } else {
          next('/login');
        }
      } 
      else {
        next('/login')
      }
    }  else {
      if(to.path == '/login' && store.getters['session/isActive']){
        next('/');
      } else {
        next();
      }
    }
  })
});

export default router
