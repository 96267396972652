export default (form, files, fileName = "files", fileTypeName = "", fileTypeId=-1) => {
    let formData = new FormData();
    let entries = Object.entries(form);
    entries.forEach((entry) => {
        if (entry[1] !== null && entry[1] !== undefined) formData.append(entry[0], entry[1]+ "");
    });
    if (files)
      files.forEach((file, index) => {
        formData.append(`${fileName}[${index}]file`, file);
        if (fileTypeName !== "" && fileTypeId !== -1) {
            formData.append(`${fileName}[${index}]${fileTypeName}`, fileTypeId);
        }
        //formData.append(`${fileName}`, file);
      });
    return formData;
  };
  