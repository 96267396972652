export default [
  {
    path: "rates",
    component: () => import("@/views/dashboard/rates/Rates"),
    meta: {
      title: "Reporte Tarifas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Reporte Tarifas", link: "/dashboard/rates" },
      ],
    },
  },
];
