const url = '/storage-consult/detail/'

export default {

    /**
     * Service for list Companys
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
     getAttachmentsAll: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    }
}