const url = '/user/'
export default {
    signup: (signature, axios, form, files) => {
        Object.keys(form).forEach((key) => {
            form[key] = form[key] + "";
        });
        let sign = signature(form);
        let formData = new FormData();
        let entries = Object.entries(form);
        entries.forEach(entry => {
            formData.append(entry[0], entry[1]);
        })
        files.forEach((file, index) => {
            formData.append(`file-${index}`, file);
        })

        return axios.post(url, formData, {
            headers: { 
            "X-SFC-Signature": sign            
            }
        })
    .then(response => {return {data: response.data}})
    .catch(error => {return {error: error?.response?.data || 'Error'}});
    },
    getUser: (axios,id) =>{
        
        return axios.get(`${url+id}/`)
        .then(response => {
            return {data: response.data}
        })
        .catch(error => {return {error: error?.response?.data}});
    },
    updateUserPassword: (signature, axios,id, user) =>{
        return axios.patch(`${url}detail/${id}`, user,{
            headers: {
                'X-SFC-Signature': signature(user)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    updateUserProfile: (signature, axios, id, user) =>{
        return axios.put(`/profile/${id}/`, user,{
            headers: {
                'X-SFC-Signature': signature(user)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error.response?.data}});
    }
    
}