<template>
  <router-link :to="to" 
    class="block py-3 px-3 rounded-full border-2 flex justify-center items-center text-xs transition"
    :class="classObject"
    :append="append">
    <b>{{label}}</b>
  </router-link>
</template>

<script>
export default {
    props: ['to', 'label', 'append', 'danger', 'success', 'invert'],
    computed: {
      classObject: function(){
        let classes =  ['border-blue', 'bg-blue',  'text-white', 'hover:bg-blue-dark', 'hover:text-white', 'hover:border-blue-dark'];
        if(this.invert){
          classes = ['border-blue', 'bg-white', 'text-blue', 'hover:bg-blue', 'hover:text-white']
        } else if(this.danger){
          classes = ['border-red-600', 'bg-white', 'text-red-600', 'hover:bg-red-600', 'hover:text-white']
        } else  if(this.success){
          classes = ['border-green-600', 'bg-green-600',  'text-white', 'hover:bg-green-800', 'hover:border-green-800']
        }
        return classes;
      }
    }
}
</script>