export default [

    //#region HISTÓRICO DE QUEJAS

    {
        path: 'complaints/historical/',
        component: () => import('@/views/dashboard/history-complaint/Management.vue'),
        meta: {
            title: 'Histórico de quejas',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Histórico de quejas', link:'/dashboard/complaints/historical/'}
            ]
        }
    },
    {
        path: 'complaints/historical/:complaint_code',
        component: () => import('@/views/dashboard/history-complaint/HistoryDetailComplaint.vue'),
        meta: {
            title: 'Histórico de una queja',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Histórico de quejas', link:'/dashboard/complaints/historical/'},
                {label: 'Histórico de queja'}
            ]
        }
    },

    {
        path: 'complaints/historical/:complaint_code/:updated_at',
        component: () => import('@/views/dashboard/history-complaint/DetailComplaintUpdated.vue'),
        meta: {
            title: 'Detalle de queja',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Histórico de quejas', link:'/dashboard/complaints/historical/'},
                {label: 'Detalle de queja'}
            ]
        }
    },

    //#endregion
     
    //#region GESTION DE QUEJAS

    {
        path: 'complaints/management',
        component: () => import('@/views/dashboard/complaints/ManageComplaints.vue'),
        meta: {
            title: 'Gestión de quejas',
            breadcrumb: [
               {label: 'Inicio', link: '/'},
                {label: 'Gestión de quejas'}
            ]
        }
    },
    {
        path: 'complaints/management/detail/:complaint_code',
        component: () => import('@/views/dashboard/complaints/ComplaintManagement.vue'),
        meta: {
            title: 'Gestión de quejas',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de quejas', link:'/dashboard/complaints/management'},
                {label: 'Gestionar queja'}
            ]
        }
    },
    {
        path: 'complaints/management/samples/',
        component: () => import('@/views/dashboard/complaints/samples/ComplaintsSamples.vue'),
        meta: {
            title: 'Listado de muestras',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de quejas', link:'/dashboard/complaints/management'},
                {label: 'Listado de muestras'}
            ]
        }
    },
    {
        path: 'complaints/management/monitoring/:complaint_code',
        name: 'MonitoringComplaint',
        component: () => import('@/views/dashboard/complaints/MonitoringHistoryComplaint.vue'),
        meta: {
            title: 'Histórico de monitoreo',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de quejas', link:'/dashboard/complaints/management'},
                {label: 'Muestra - Histórico de queja'},
            ]
        }
    },
    {
        path: 'complaints/management/sample',
        name: 'ComplaintsManagementSample',
        component: () => import('@/views/dashboard/complaints/samples/ComplaintsSample.vue'),
        meta: {
            title: 'Muestra no.',
            param: 'sample_id',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de quejas', link:'/dashboard/complaints/management'},
                {label: 'Listado de muestras', link: '/dashboard/complaints/management/sample'},
                {label: 'Muestra', param: 'sample_id'}
            ]
        }
    },
    {
        path: 'complaints/management/sample/detail/:complaint_code/:criterion_id',
        name: 'ComplaintsManagementOne',
        component: () => import('@/views/dashboard/complaints/ComplaintManagement.vue'),
        meta: {
            title: 'Gestión de quejas',
            breadcrumb: [
               {label: 'Inicio', link: '/'},
                {label: 'Gestión de quejas', link:'/dashboard/complaints/management'},
                {label: 'Muestra - Gestionar queja'},
            ]
        }
    },
  //#endregion

    //#region BUSQUEDA DE QUEJAS
    {
        path: 'complaints/search',
        name: 'SearchComplaints',
        props: true,
        component: () => import('@/views/dashboard/complaints/SearchComplaints.vue'),
        meta: {
            title: 'Búsqueda de quejas',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Búsqueda de quejas',link:'/dashboard/complaints/search'},
            ]
        }
    },
    {
        path: 'complaints/desist/',
        component: () => import('@/views/dashboard/complaints/MessageDesist.vue'),
        name: 'DashboardMessageDesist',
        meta: {
            title: 'Búsqueda de quejas',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Búsqueda de quejas',link:'/dashboard/complaints/search'},
                {label: 'Desistimiento de queja'}
            ]
        }
    },
    
    {
        path: 'complaint/details/',
        name: 'ComplaintDetails',
        props: true,
        component: () => import('@/views/dashboard/complaints/ComplaintDetails.vue'),
        meta: {
            title: 'Búsqueda de quejas',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Búsqueda de quejas',link:'/dashboard/complaints/search/'},
                {label: 'Ver detalle de queja',link:'/dashboard/complaint/details/'}
            ]
        }
    },
    {
        path: 'complaint/details/:complaint_id/anexos/:estado_cod/:annexesSize',
        component: ()=>import('@/views/dashboard/complaints/Anexos.vue'),
        meta: {
            title: 'Adjuntar anexos',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Búsqueda de quejas',link:'/dashboard/complaints/search/'},
                {label: 'Ver detalle de queja',link:'/dashboard/complaint/details/'},
                {label: 'Subir anexos'},
            ]
        }
    },
    {
        path: 'complaint/details/:complaint_id/escalate/:estado_cod',
        component: ()=>import('@/views/dashboard/complaints/Replica.vue'),
        meta: {
            title: 'Escalar la queja',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Búsqueda de quejas',link:'/dashboard/complaints/search/'},
                {label: 'Ver detalle de queja',link:'/dashboard/complaint/details/'},
                {label: 'Escalar'},
            ]
        }
        
    },
    {
        path: 'complaint/details/:complaint_id/replica/:estado_cod',
        component: ()=>import('@/views/dashboard/complaints/Replica.vue'),
        meta: {
            title: 'Replicar queja',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Búsqueda de quejas',link:'/dashboard/complaints/search/'},
                {label: 'Ver detalle de queja',link:'/dashboard/complaint/details/'},
                {label: 'Reabrir'},
            ]
        }
    },
    {
        path: 'complaint/details/:complaint_id/calification',
        component: () => import('@/views/dashboard/complaints/Calification'),
        meta: {
            title: 'Calificar la experiencia',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Búsqueda de quejas',link:'/dashboard/complaints/search/'},
                {label: 'Ver detalle de queja',link:'/dashboard/complaint/details/'},
                {label: 'Evalúa'}
            ]
        }
    },
    //#endregion
   
    //#region RADICAR NUEVA QUEJA
    {
        path: 'complaints/',
        component: () => import('@/views/dashboard/complaints/NewComplaint.vue'),
        meta: {
            title: 'Radicar una nueva inconformidad',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Radicar nueva inconformidad',link:'/dashboard/complaints/'}
            ]
        }
    },
    {
        path: 'complaint/new/completed/',
        name: 'RegisteredComplaint',
        props: true,
        component: () => import('@/views/dashboard/complaints/RegisteredComplaint.vue'),
        meta: {
            title: 'Radicar una nueva inconformidad',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Radicar nueva inconformidad',link:'/dashboard/complaints/'},
                {label: 'Formulario nueva inconformidad'},
                {label: 'Código de registro'}
            ]
        }
    },
    {
        path: 'complaints/new/registeredcustomer',
        name: 'ComplaintsNewRegisteredCustomer',
        component: () => import('@/views/dashboard/complaints/RegisteredCustomer.vue'),
        meta: {
            title: 'Radicar una nueva inconformidad',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Radicar nueva inconformidad',link:'/dashboard/complaints/'},
                {label: 'Radicar nueva inconformidad con una persona registrada'}
            ]
        }
    },
    {
        path: 'complaints/new/customerregistration',
        name: 'ComplaintsNewCustomerRegistration',
        component: () => import('@/views/dashboard/complaints/CustomerRegistration.vue'),
        meta: {
            title: 'Radicar una nueva inconformidad',
            breadcrumb: [
               {label: 'Inicio', link: '/'},
                 {label: 'Radicar nueva inconformidad',link:'/dashboard/complaints/'},
                {label: 'Radicar nueva inconformidad con una persona sin registrar'}
            ]
        }
    },
    {
        name: 'ComplaintRegistration',
        path: 'complaint/create',
        props: true,
        component: () => import('@/views/dashboard/complaints/ComplaintRegistration.vue'),
        meta: {
            title: 'Radicar una nueva inconformidad',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                 {label: 'Radicar nueva inconformidad',link:'/dashboard/complaints/'},
                {label: 'Formulario nueva inconformidad'}
            ]
        }
    },
    //#endregion
      
];