import axios from 'axios'
import store from '../store'
import router from '../router/index'

let instance =  axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

instance.defaults.headers.common['X-SFC-Origin'] = process.env.NODE_ENV == 'development' ? 'desarrollo' : 'application';

export default {
  install(Vue) {

    const refreshToken = async ()=>{
      
      let refresh = store.state.session.refresh;
      
      const response = await instance.post("/token/refresh/", {refresh}, {
        headers: {  "X-SFC-Signature": Vue.prototype.$signature({refresh})}
      });
      if(response.data) {
        store.commit('session/setSessionAccess', response.data.access);
        store.commit('session/setSessionRefresh', response.data.refresh);
      }
      else{
        await store.dispatch('session/logout')
        window.location.href = '/login'
      }

    }


    // Interceptor over request
    instance.interceptors.request.use(function (config) {
      if(config.method == 'get'){
        let url = config.baseURL + config.url;
        let sign = Vue.prototype.$signature(url);
        config.headers['X-SFC-Signature'] = sign;
        if(store.getters['session/isActive']){
          config.headers['Authorization'] = `Bearer ${store.state.session.access}`;
        }
      } else if(config.method == 'post' || config.method == 'patch' || config.method == 'put') {
        if(store.getters['session/isActive']){
          config.headers['Authorization'] = `Bearer ${store.state.session.access}`;
        }
      }
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    }, { synchronous: true });

    // Incerteptor over response
    instance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, async function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if(error.response.status == 401){
        let msg = error.response.data?.message[0]?.message;
        if(msg== "Token 'exp' claim has expired"|| msg == "Token is invalid or expired" || msg == "El token es inválido o ha expirado"){
          await refreshToken();
          const config = error.config;
          return instance({method: config.method, url:config.url, data:config.data, headers: config.headers})
        }
      }
      if(error.response.status == 503){
          router.push({name: 'repair', params:{msg: error.response.data.message, error: true}}).catch((err)=>{return err})
          return
      }
      return Promise.reject(error);
    });

    Vue.prototype.$apiBase = instance;
  }
}