import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';  
// Modules
import sessionModule from './modules/session.store'
import formModule from './modules/form.store'
import complaintsModule from './modules/complaints.store'


Vue.use(Vuex)
const vuexLocalStorage = new VuexPersist({
  key: 'SFC', 
  storage: window.sessionStorage,
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: state => ({
    forms: state.form,
    session: state.session
  
  })
  
})



export default new Vuex.Store({
  modules: {
    session: sessionModule,
    form: formModule,
    complaints: complaintsModule
  },
  state: {},
  mutations: {},
  actions: {
    initStore({dispatch}){
      // dispatch('session/checkSession');
      dispatch("session/loadUser");
      dispatch("form/loadFormOptions");
    }
   
  },
  plugins: [vuexLocalStorage.plugin]
})
