const url = '/company/'

export default {

    createCompanyExcel: (axios, sign, data) => {
        return axios.post(`${url}massive/`, data, {
            headers: {
                'X-SFC-Signature': sign({})
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});

    },

    inactivateMassiveCompanys: (axios, sign, data) => {
        return axios.post(`${url}inactivations/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});

    }
}