const url = "/alerts/";

export default {
  /**
   * @param {*} query
   * @param {*} axios
   * @returns
   */
  getAlerts: (axios, query) => {
    return axios
      .get(`${url}history/${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  /**
   * @param {*} query
   * @param {*} axios
   * @returns
   */
  getAlertTypes: (axios, query) => {
    return axios
      .get(`${url}type_alert/${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  /**
   * @param {*} query
   * @param {*} axios
   * @returns
   */
  getAlertSettings: (axios, query) => {
    return axios
      .get(`${url}configuration_alert/${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  /**
   * @param {*} alert_configuration
   * @param {*} axios
   * @returns
   */
  setAlertSettings: (axios, sign, alert_configuration, configuration_id) => {
    return axios
      .patch(
        `${url}configuration_alert/${configuration_id}/`,
        alert_configuration,
        { headers: { "X-SFC-Signature": sign(alert_configuration) } }
      )
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },

  /**
   * @param {*} sign
   * @param {*} axios
   * @param {*} alert_id
   * @returns
   */
  setReadAlert: (axios, sign, alert) => {
    return axios
      .post(`${url}mark_alert/`, alert, {
        headers: {
          "X-SFC-Signature": sign(alert),
        },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  /**
   * @param {*} sign
   * @param {*} axios
   * @param {*} query
   * @returns
   */
  getNotViewdAlert: (axios, query = `page_size=7`) => {
    return axios
      .get(`${url}new_alerts/?${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  setStateSetting: (axios, sign, data) => {
    return axios.post(`${url}change_state_configuration/`, data, {
      headers: {
        "X-SFC-Signature": sign(data),
      },
    });
  },
  //NotificationsEv  
  getNotificationsEv: (axios, query) => {
    return axios
      .get(`${url}ev_history/${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  getNotificationTypes: (axios, query) => {
    return axios
      .get(`${url}ev_type_alert/${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  getStatusTypes: (axios, query) => {
    return axios
      .get(`${url}ev_status_options/${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  setReadNotificationEv: (axios, sign, alert) => {
    return axios
      .post(`${url}ev_mark_alert/`, alert, {
        headers: {
          "X-SFC-Signature": sign(alert),
        },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  getNotViewdAlertEv: (axios, query = `page_size=7`) => {
    return axios
      .get(`${url}ev_new_alerts/?${query}`)      
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  setReadAlertEv: (axios, sign, alert) => {
    return axios
      .post(`${url}ev_mark_alert/`, alert, {
        headers: {
          "X-SFC-Signature": sign(alert),
        },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
};
