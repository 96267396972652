const url = '/complaint_detail/'

export default {
    
    /**
     * Service for list users
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
    getDetailComplaint: (axios, id) => {
        return axios.get(`${url}${id}/`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
}
