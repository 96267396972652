const url = '/complaint_historical/'

export default {

    getListBigquery: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },

    getBigqueryComplaint: (axios, complaint, query) => {
        return axios.get(`${url}${complaint}/${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    }
}