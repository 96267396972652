export default [
    {
        path: 'roles',
        component: () => import('@/views/dashboard/roles/Managment'),
        meta: {
            title: 'Gestión de roles',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de roles', link:'/dashboard/roles'},
            ]
        }
    },
    {
        path: 'roles/add',
        component: () => import('@/views/dashboard/roles/AddRole'),
        meta: {
            title: 'Gestión de roles',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de roles', link:'/dashboard/roles'},
                {label: 'Nuevo rol'}
            ]
        }
    },
    {
        path: 'roles/edit',
        component: () => import('@/views/dashboard/roles/EditRole'),
        meta: {
            title: 'Gestión de roles',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de roles', link:'/dashboard/roles'},
                {label: 'Editar rol'}
            ]
        }
    },
    {
        path: 'roles/usermanagement/:roleid/:state/',
        component: () => import('@/views/dashboard/roles/UserRole'),
        meta: {
            title: 'Gestión de roles',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de roles', link:'/dashboard/roles'},
                {label: 'Inactivación de usuarios asociados'}
            ]
        }
    },
]