<template>
  <div class="w-full mt-5 flex flex-col">
    <div class="grid grid grid-cols-4 ">
      <p class="
        col-start-1 col-span-4
        w-full
        mb-2
        text-xs
        md:text-xs
        text-left text-gray-500 text-opacity-80
        " :class="{ 'text-red-500': invalid }" v-if="!!label">
        <b>{{ label }}</b>

        <span v-if="required" class="text-red-500">*</span>
      </p>

      <a-checkbox-group class="col-start-1 col-span-4 text-left" v-model="checked_values" @change="groupCheckUpdated">
          <a-row>
            <div v-for="(option, index) in data">
              <a-col :span="24" class="mt-1">
                  <a-checkbox :disabled="disabled" :value="option.id">{{ option.labelName }}</a-checkbox>
              </a-col>
            </div>
          </a-row>
      </a-checkbox-group>

    </div>
    <p v-if="invalid" class="mt-2 text-xs text-left text-red-500">
      Este campo es obligatorio
    </p>
  </div>
</template>

<script>
export default {
  props: ["value", "data", "label", "required", "disabled"],
  data() {
    return {
      checked_values: [],
      invalid: false,
    };
  },
  created() {
    this.checked_values = this.value?? this.checked_values;
  },
  methods: {
    groupCheckUpdated() {
      this.$emit("notify-changes", this.checked_values);
    },
    setInvalid(invalid) {
      if (this.required) {
        this.invalid = invalid;
      }
    },
  },
};
</script>
