const getDefaultState = () => {
  return {
    optionsPersonType: [],
    optionsIdentificationType: [],
    optionsCountries: [],
    optionsDepartments: [],
    optionsCity: [],
    optionsGender: [],
    optionsChannel: [],
    optionsProducts: [],
    optionsReasons: [],
    optionsControlEntity: [],
    optionsConditions: [],
    optionsStates: [],
    optionsCompanies: [],
    optionsCriterions: [],
    optionsGroupCompany: [],
    optionsPosition: [],
    optionsCompanyType: [],
    optionsDirections: [],
    optionsReception: [],
    optionsDuplicityStates: [],
  };
};

const state = getDefaultState;

const mutations = {
  setFormOptions(state, payload) {
    state.optionsPersonType = payload.optionsPersonType;
    state.optionsIdentificationType = payload.optionsIdentificationType;
    state.optionsCountries = payload.optionsCountries;
    state.optionsDepartments = payload.optionsDepartments;
    state.optionsCity = payload.optionsCity;
  },
  setComplaintOptions(state, payload) {
    state[Object.keys(payload)[0]] = Object.values(payload)[0];
  },
  setDashboardOptions(state, payload) {
    state.optionsCriterions = payload.optionsCriterions;
    state.optionsPosition = payload.optionsPosition;
    state.optionsGroupCompany = payload.optionsGroupCompany;
    state.optionsCompanyType = payload.optionsCompanyType;
    state.optionsDirections = payload.optionsDirections;
    state.optionsReception = payload.optionsReception;
  },
  setListOptions(state, payload) {
    state[payload.listName] = payload.listData;
  },
};
const actions = {
  // Load all form options for USER REGISTRATION
  loadFormOptions({ commit, dispatch }) {
    let baseUrl = this._vm.$apiBase.defaults.baseURL;
    let countries = dispatch("loadOptions", { url: baseUrl + "/api/country/" });
    let identificationType = dispatch("loadOptions", {
      url: baseUrl + "/api/identificationtype/",
    });
    let personType = dispatch("loadOptions", {
      url: baseUrl + "/api/persontype/",
    });
    let departments = dispatch("loadOptions", {
      url: baseUrl + "/api/department/",
    });

    Promise.all([personType, identificationType, countries, departments])
      .then((response) => {
        let optionsPersonType = response[0];
        let optionsIdentificationType = response[1].filter((identification) => {
          if (identification.name != "NIT") {
            return identification;
          }
        });
        let optionsCountries = response[2].map((item) => {
          return {
            ...item,
            id: item.code,
          };
        });
        let optionsDepartments = response[3].map((item) => {
          return {
            ...item,
            id: item.code,
          };
        });

        commit("setFormOptions", {
          optionsPersonType,
          optionsIdentificationType,
          optionsCountries,
          optionsDepartments,
        });
      })
      .catch((e) => console.error(e));
  },
  // Load all form options for COMPLAINTS registration with TOKEN
  loadComplaintOptions({ commit, dispatch }) {
    let baseUrl = this._vm.$apiBase.defaults.baseURL;
    let companies = dispatch("loadOptions", {
      url: baseUrl + "/api/company/",
      auth: true,
    });
    let channel = dispatch("loadOptions", {
      url: baseUrl + "/api/channel/",
      auth: true,
    });
    let states = dispatch("loadOptions", {
      url: baseUrl + "/api/state/",
      auth: true,
    });
    let gender = dispatch("loadOptions", {
      url: baseUrl + "/api/gender/",
      auth: true,
    }); /*
    let duplicityStates = dispatch("loadOptions", {
      url: baseUrl + "/api/duplicity/",
      auth: true,
    });*/
    let product = dispatch("loadOptions", {
      url: baseUrl + "/api/product/",
      auth: true,
    });
    let reason = dispatch("loadOptions", {
      url: baseUrl + "/api/reason/",
      auth: true,
    });
    let controlEntity = dispatch("loadOptions", {
      url: baseUrl + "/api/controlentity/",
      auth: true,
    });
    let conditions = dispatch("loadOptions", {
      url: baseUrl + "/api/condition/",
      auth: true,
    });

    channel.then((value) => {
      const optionsChannel = value;
      commit("setComplaintOptions", { optionsChannel });
    });
    states.then((value) => {
      const optionsStates = value;
      commit("setComplaintOptions", { optionsStates });
    });
    gender.then((value) => {
      const optionsGender = value;
      commit("setComplaintOptions", { optionsGender });
    }); /*
    duplicityStates.then((value) => {
      const optionsDuplicityStates = value;
      commit("setComplaintOptions", { optionsDuplicityStates });
    });*/
    product.then((value) => {
      const optionsProducts = value;
      commit("setComplaintOptions", { optionsProducts });
    });
    reason.then((value) => {
      const optionsReasons = value;
      commit("setComplaintOptions", { optionsReasons });
    });
    controlEntity.then((value) => {
      const optionsControlEntity = value;
      commit("setComplaintOptions", { optionsControlEntity });
    });
    conditions.then((value) => {
      const optionsConditions = value;
      commit("setComplaintOptions", { optionsConditions });
    });
    companies.then((value) => {
      const optionsCompanies = value;
      commit("setComplaintOptions", { optionsCompanies });
    });
  },
  // Load all form options for DASHBOARD registration with TOKEN
  loadDashboardOptions({ commit, dispatch }) {
    let baseUrl = this._vm.$apiBase.defaults.baseURL;
    let criterions = dispatch("loadOptions", {
      url: baseUrl + "/api/criterion/",
      auth: true,
    });
    let positions = dispatch("loadOptions", {
      url: baseUrl + "/api/position/",
      auth: true,
    });
    let groupCompany = dispatch("loadOptions", {
      url: baseUrl + "/api/group/",
      auth: true,
    });
    let companyType = dispatch("loadOptions", {
      url: baseUrl + "/api/companytype/",
      auth: true,
    });
    let directions = dispatch("loadOptions", {
      url: baseUrl + `/api/direction/`,
      auth: true,
    });
    let reception = dispatch("loadOptions", {
      url: baseUrl + `/api/instance/`,
      auth: true,
    });

    Promise.allSettled([
      criterions,
      positions,
      groupCompany,
      companyType,
      directions,
      reception,
    ])
      .then((response) => {
        let optionsCriterions = response[0].value;
        let optionsPosition = response[1].value;
        let optionsGroupCompany = response[2].value;
        let optionsCompanyType = response[3].value;
        let optionsDirections = response[4].value;
        let optionsReception = response[5].value;

        commit("setDashboardOptions", {
          optionsCriterions,
          optionsPosition,
          optionsGroupCompany,
          optionsCompanyType,
          optionsDirections,
          optionsReception,
        });
      })
      .catch((error) => console.error(error.response));
  },

  // Load municipalities options by id department
  async loadMunicipalitiesOptions({ dispatch }, payload) {
    if (payload.department == null || payload.department == undefined) return;
    let baseUrl = this._vm.$apiBase.defaults.baseURL;
    let url = `/api/municipality/?department=${payload.department}`;
    let municipalities = dispatch("loadOptions", {
      url: baseUrl + url,
      auth: true,
    });
    return municipalities.then((response) => {
      let optionsMunicipalities = response.map((item) => {
        return {
          ...item,
          id: item.code,
        };
      });
      return optionsMunicipalities;
    });
  },
  // Load reasons options by id company
  loadReasonsOptions({ dispatch }, payload) {
    if (payload.company == null || payload.company == undefined) return;
    let baseUrl = this._vm.$apiBase.defaults.baseURL;
    let url = `/api/reason/company/?id_company=${payload.company}`;
    let reasons = dispatch("loadOptions", { url: baseUrl + url, auth: true });
    return reasons.then((response) => {
      return response;
    });
  },
  // Load products options by id company
  loadProductsOptions({ dispatch }, payload) {
    if (payload.company == null || payload.company == undefined) return;

    let baseUrl = this._vm.$apiBase.defaults.baseURL;
    let url = `/api/product/company/?id_company=${payload.company}`;
    let reasons = dispatch("loadOptions", { url: baseUrl + url, auth: true });
    return reasons.then((response) => {
      return response;
    });
  },
  // Load options with pagination
  async loadOptions(context, payload) {
    try {
      // To_Do: Reevaluar si está lógica es necesaria para el funcionamiento general
      let values = [];
      while (payload.url) {
        let url = payload.url;
        url = "/api" + url.split("/api")[1];
        const response = await this._vm.$apiBase(url);
        const { results, next } = response.data;
        values.push(...results);
        payload.url = next;
      }
      return values;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  loadListOptions({ commit, dispatch }, payload) {
    if (payload == null || payload == undefined) return;
    let baseUrl = this._vm.$apiBase.defaults.baseURL;
    let url = `/api/${payload.endpoint}`;
    dispatch("loadOptions", { url: baseUrl + url, auth: true }).then(
      (response) => {
        commit("setListOptions", {
          listName: payload.name,
          listData: response,
        });
      }
    );
  },
};

const form = {
  namespaced: true,
  state,
  mutations,
  actions,
};

export default form;
