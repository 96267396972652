//import { useCache } from "../../../cache/cache";

const url = '/queries-servi/file/consolidated-ac-link'
//const cache = useCache()

export default {
    /**
     * Service for list complaints
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
     getComplaintsAllFile: (axios, query) => {
        //const responseCache = cache.readFromCache(`${url}${query}`);
        //if (responseCache){
        //    console.log(responseCache)
        //    return responseCache;
        //}
        //console.log('Refresh data...')

        // axios.defaults.baseURL = "http://localhost:5000/api";
        return axios.get(`${url}${query}`)
        .then(response => {
            //cache.writeToCache(`${url}${query}`, {data: response.data});
            
            return {data: response.data}
        })
        .catch(error => {return {error: error?.response?.data}});
    }
}