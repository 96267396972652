const url = '/company_list/'

export default {

    /**
     * Service for creater admin Company
     * @param {*} sign signature
     * @param {*} axios instance axios
     * @param {*} data Company data
     * @returns 
     */
    createCompany: (axios, sign, data) => {
        return axios.post(`${url}`, data,{
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for list Companys
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
    readCompany: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for retrieve Company data
     * @param {*} axios 
     * @param {*} id Company id
     * @returns 
     */
    getCompany: (axios, id) => {
        return axios.get(`${url}${id}/`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    updateCompany: (axios, sign, data, id) => {
        return axios.put(`${url}${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    changeStateCompany: (axios, sign, data, id) => {
        return axios.patch(`${url}${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});

    }
}