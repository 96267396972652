const url = '/complaint_management/'

export default {
    saveManagement: (axios, sign, data) => {
        return axios.post(`${url}`, data,{
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    getManagementComplaint: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    }
}
