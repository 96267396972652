const url = '/storage-consult/url/'
const urlQueries = '/queries-servi/file/get-url'


export default {

    /**
     * Service for list Companys
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
     getAttachmentUrl: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    getAttachmentUrlByPath: (axios, query) => {
        return axios.get(`${urlQueries}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    }
}