<template>
  <div class="bg-blue-400  w-full h-full rounded-full flex justify-center align-center items-center m-2">
    <h1 class="text-9xl text-blue font-bold">{{ number }}</h1>
  </div>
</template>

<script>
export default {
  props: { number: { type: String, required: true } },
};
</script>
