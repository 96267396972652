const url = "/complaint_duplicity/";

export default {
  getDuplicateComplaints: (axios, query) => {
    return axios
      .get(`${url}${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  getDuplicateComplaintsByUser: (axios, query) => {
    return axios
      .get(`${url}${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  managementDuplicateComplaints: (
    axios,
    idPrincipal,
    complaints,
    signature
  ) => {
    return axios
      .put(`${url}${idPrincipal}/`, complaints, {
        headers: {
          "X-SFC-Signature": signature(complaints),
        },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  releaseDuplicateComplaints: (axios, complaints, signature) => {
    return axios
      .post(`${url}`, complaints, {
        headers: {
          "X-SFC-Signature": signature(complaints),
        },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
};
