const url = '/complaint_sample/'

export default {

    /**
     * Service for creater admin Sample
     * @param {*} sign signature
     * @param {*} axios instance axios
     * @param {*} data Sample data
     * @returns 
     */
    createSample: (axios, sign, data) => {
        return axios.post(`${url}`, data,{
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for list Samples
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
    readSample: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for retrieve Sample data
     * @param {*} axios 
     * @param {*} id Sample id
     * @returns 
     */
    getSample: (axios, id, query) => {
        return axios.get(`${url}${id}/${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    }
}
