export default [

    {
    path: '/error',
    component: () => import('@/layouts/FullWidthLayout.vue'),
    meta: {
        requiresAuth: false
    },
        children: [
       {
            path: '503',
            name:'repair',
            props:true,
            component: () => import('@/views/shared/RepairPage'),
        },
        ]
    }
]