const url = '/confirmcode/'
export default {
    verifyCode(axios, sign, form){
        return axios.post(`${url}code/`, form, {
            headers: {'X-SFC-Signature': sign(form)}
          })
            .then(response => ({data: response.data}))
            .catch(error => ({error: error?.response?.data}))
    },
    resendCode(axios, sign, form){
        return axios.post(`${url}resend/`, form, {
            headers: {'X-SFC-Signature': sign(form)}
          })
            .then(response => ({data: response.data}))
            .catch(error => ({error: error?.response?.data}))
    }
}