const url = '/group/'
export default {

    /**
     * Service for creater admin Role
     * @param {*} sign signature
     * @param {*} axios instance axios
     * @param {*} data Role data
     * @returns 
     */
    createRole: (axios, sign, data) => {
        return axios.post(`${url}`, data,{
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for list Roles
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
    readRole: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for retrieve Role data
     * @param {*} axios 
     * @param {*} id Role id
     * @returns 
     */
    getRole: (axios, id) => {
        return axios.get(`${url}${id}/`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    updateRole: (axios, sign, data, id) => {
        return axios.put(`${url}${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    changeStateRole: (axios, sign, data, id) => {
        return axios.patch(`${url}${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});

    }
}
