const url = '/direction/'
export default {

    /**
     * Service for create a direction
     * @param {*} sign signature
     * @param {*} axios instance axios
     * @param {*} data direction data
     * @returns 
     */
    createDirection: (axios, sign, data) => {
        return axios.post(`${url}`, data,{
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for list directions
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
    readDirections: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    /**
     * Service for retrieve Direction data
     * @param {*} axios 
     * @param {*} id Direction id
     * @returns 
     */
    getDirection: (axios, id) => {
        return axios.get(`${url}${id}/`)
        .then(response => {return {data: response.data.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    updateDirection: (axios, sign, data, id) => {
        return axios.put(`${url}${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    changeStateDirection: (axios, sign, data, id) => {
        return axios.patch(`${url}${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});

    }
}
