const url = "/dcf/annual_report/";

import generateFormData from "../../../utils/generateFormData";

export default {
  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} getCounter
   * @returns  Data after created or error in creation
   */
  getCounters: (axios,query, signature, data) => {
    return axios
      .post(`/annual-report-servi/get_counters${query}`, data, {
        headers: { "X-SFC-Signature": signature(data) },
      })
      .then((response) => {
        console.log(response.data);

        return { data: response.data };
      })
      .catch((error) => {
        console.log(error.response.data);
        return { error: error.response.data };
      });
  },
  /**
   * @param {*} axios
   * @returns Report list v2
   */
  // TO_DO delete for axiosForm
  getReportsV2: (axios, query, DocID = "") => {
    let url = "";
    if (DocID != "") {
      url = "/annual-report-servi/get_report";
    } else {
      url = "/annual-report-servi/list_reports";
    }
    return axios
      .get(`${url}${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },

  /**
   * Anual Report
   *
   * @param axios
   * @param query
   * @param DocID
   * @returns {*}
   */
  getAnualReport: (axios, query, DocID = "") => {
    let url = "";
    if (DocID != "") {
      url = "/annual-report-servi/get_report";
    } else {
      url = "/annual-report-servi/list_ev_reports";
    }
    return axios
      .get(`${url}${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} AnualReport
   * @returns  Data after created or error in creation
   */
  createAnexo: (axios, signature, dataAnexo) => {
    let formData = new FormData();

    formData.append(`step${dataAnexo.step}`, dataAnexo.file);
    formData.append("document_id", dataAnexo.id);

    return axios
      .post(`queries-servi/file/annual_report_attach`, formData, {
        headers: {
          "X-SFC-Signature": signature(dataAnexo),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} AnualReport
   * @returns  Data after created or error in creation
   */
  createAnualReport: (axios, signature, dataReport) => {
    return axios
      .post(`/annual-report-servi/create_report`, dataReport, {
        headers: { "X-SFC-Signature": signature(dataReport) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  createAnualEVReport: (axios, signature, dataReport, id) => {
    return axios[id ? 'patch' : 'post']
      (`/annual-report-servi/${id ? 'update_report_ev' : 'upload_ev_report'}?id=${dataReport.document_id}`, dataReport, {
        headers: { "X-SFC-Signature": signature(dataReport) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} AnualReport
   * @returns  Data after created or error in creation
   */ updateAnualReport: (axios, signature, dataReport, id) => {
    return axios
      .patch(`/annual-report-servi/update_report?id=${id}`, dataReport, {
        headers: { "X-SFC-Signature": signature(dataReport) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
  /**
   * @param {*} axios
   * @returns Report list
   */
  getReports: (axios, query) => {
    return axios
      .get(`${url}${query}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} reportForm
   * @returns Data after report created or error in creation
   */
  createReport: (axios, signature, reportForm) => {
    return axios
      .post(`${url}`, reportForm, {
        headers: { "X-SFC-Signature": signature(reportForm) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} reportDetail
   * @returns  Data after created or error in creation
   */
  createReportDetail: (axios, signature, reportDetail) => {
    const formData = generateFormData(reportDetail.form, reportDetail.files);
    return axios
      .post(`${url}detail`, formData, {
        headers: { "X-SFC-Signature": signature(reportDetail.form) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} sectionForm
   * @returns Data adter created or creation error
   */
  createSectionReport: (axios, signature, sectionForm) => {
    const formData = generateFormData(sectionForm.form, sectionForm.files);
    return axios
      .post(`${url}section`, formData, {
        headers: { "X-SFC-Signature": signature(sectionForm.form) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} incidentForm
   * @returns Data after created or creation error
   */
  createIncidentReport: (axios, signature, incidentForm) => {
    const formData = generateFormData(incidentForm.form, incidentForm.files);
    return axios
      .post(`${url}incident`, formData, {
        headers: { "X-SFC-Signature": signature(incidentForm.form) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} attentionForm
   * @returns Data after created or creation error
   */
  createAttention: (axios, signature, attentionForm) => {
    const formData = generateFormData(attentionForm.form, attentionForm.files);
    return axios
      .post(`${url}attention`, formData, {
        headers: { "X-SFC-Signature": signature(attentionForm.form) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  createNewMeet: (axios, signature, newMeet) => {
    const formData = generateFormData(newMeet.form, newMeet.files);
    return axios
      .post(`${url}additional`, formData, {
        headers: { "X-SFC-Signature": signature(newMeet.form) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} id
   * @param {*} step
   * @returns Specific step report data
   */
  getStepReport: (axios, id, step) => {
    return axios
      .get(`${url}${id}/step/${step}`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} companyReportForm
   * @returns Data after created or creation error
   */
  createCompanyReport: (axios, signature, companyReportForm, files) => {
    let formData = new FormData();
    let entries = Object.entries(companyReportForm);
    Object.keys(companyReportForm).forEach((key) => {
      companyReportForm[key] = companyReportForm[key] + "";
    });
    entries.forEach((entry) => {
      formData.append(entry[0], entry[1]);
    });
    files.forEach((file, index) => {
      formData.append(`file-complaint-${index}`, file);
    });
    return axios
      .post(`${url}company`, formData, {
        headers: { "X-SFC-Signature": signature(companyReportForm) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} query
   * @returns Company report or response error
   */
  getCompanyReport: (axios) => {
    return axios
      .get(`${url}company`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },

  /**
   *
   * @param {*} axios
   * @param {*} signature
   * @param {*} reportId
   * @param {*} obj
   * @returns Result of finished report or error
   */
  setFinishReport: (axios, signature, reportId, obj) => {
    return axios
      .put(`${url}${reportId}/`, obj, {
        headers: { "X-SFC-Signature": signature(obj) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
};
