const url = "/login/";
export default {
  login: (signature, axios, credentials) => {
    return axios
      .post(url, credentials, {
        headers: { "X-SFC-Signature": signature(credentials) },
      })
      .then((response) => {
        if (response?.data?.error) {
          throw { response: { data: response.data } };
        } else {
          return { data: response.data };
        }
      })
      .catch((error) => {
        return { error: error?.response?.data || "Error" };
      });
  },

  logout: (signature, axios, credentials) => {
    return axios
      .post("logout/", credentials, {
        headers: { "X-SFC-Signature": signature(credentials) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error.response.data };
      });
  },
};
