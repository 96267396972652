import InterceptorModel from "./interceptor_model";

export default {
  validate: (response) => {
    // console.log("response", response);
    if (response.status == 500) {
      return InterceptorModel.get("500");
    }
    const allowedStatusCodes = [200, 201, 202];

    if (!allowedStatusCodes.includes(response.status)) {
      return InterceptorModel.get("undefined");
    }

    return InterceptorModel.get("success", response.data);
  },
};
