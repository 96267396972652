<template>
  <div>
    <div class="flex items-center justify-center w-full">
        <label :for="id" class="flex items-center cursor-pointer">
            <!-- toggle -->
            <div class="relative">
            <!-- input -->
            <input type="checkbox" :id="id" class="sr-only" v-model="isOn">
            <!-- line -->
            <div class="box block bg-gray-300 w-10 h-6 rounded-full"></div>
            <!-- dot -->
            <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
            </div>
            <!-- label -->
            <!-- <div class="ml-3 text-gray-700 font-medium">
            Toggle Me!
            </div> -->
        </label>
    </div>
  </div>
</template>

<script>
export default {
    props: ['id', 'handler'],
    data(){
        return {
            isOn: false
        }
    },
    watch: {
        'isOn': function(value){
            if(value){
                window.alert('active')
            } else {
                window.alert('inactive')
            }
        }
    }
}
</script>

<style >
input:checked ~ .dot {
  transform: translateX(100%);
}
input:checked ~ .box {
  background-color:  #60DF65;
}
</style>