<template>
  <div>
    <button @click="onClick">
      <slot v-if="!loading"></slot>
      <div v-else>
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 24px"
            spin
          />
        </a-spin>
        Generando archivo...
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: "vue-excel-xlsx",
  props: {
    columns: {
      type: Array,
      default: () => [],
    },

    data: {
      type: Array,
      default: () => [],
    },
    columnsSheets: {
      type: Array,
      default: () => [],
    },
    dataSheets: {
      type: Array,
      default: () => [],
    },
    sheetNames: {
      type: Array,
      default: () => [],
    },
    fileName: {
      type: String,
      default: "excel",
    },
    sheetName: {
      type: String,
      default: "SheetName",
    },
    fileType: {
      type: String,
      default: "xlsx",
      validator: (val) => ["xlsx", "xls", "csv"].includes(val),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    book: {
      type: Boolean,
      default: false,
    },
    async: {
      type: Boolean,
      default: false,
    },
    transpose: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onClick() {
      this.showToast("info", "Generando archivo.");

      if (!this.async) this.book ? this.exportBook() : this.exportExcel();
    },
    exportBook() {
      let XLSX = require("xlsx");
      let wb = XLSX.utils.book_new();
      let fileName = this.fileName + "." + this.fileType;
      this.dataSheets.forEach((sheet, idx) => {
        let createXLSLFormatObj = [];
        let newXlsHeader = [];

        if (this.columnsSheets[idx].length === 0) {
          return;
        } else if (this.dataSheets[idx].length === 0) {
          return;
        } else {
          this.columnsSheets[idx].map((column) => {
            newXlsHeader.push(column.label);
          });

          createXLSLFormatObj.push(newXlsHeader);
          this.dataSheets[idx].map((value) => {
            let innerRowData = [];
            this.columnsSheets[idx].map((val) => {
              let fieldValue = value[val.field];
              if (val.field.split(".").length > 1) {
                fieldValue = this.getNestedValue(value, val.field);
              }
              if (val.dataFormat && typeof val.dataFormat === "function") {
                innerRowData.push(val.dataFormat(fieldValue));
              } else {
                innerRowData.push(fieldValue);
              }
            });
            createXLSLFormatObj.push(innerRowData);
          });
          if (this.transpose[idx]) {
            let aoa = [];

            for (let i = 0; i < createXLSLFormatObj.length; ++i) {
              for (let j = 0; j < createXLSLFormatObj[i].length; ++j) {
                if (!aoa[j]) aoa[j] = [];
                aoa[j][i] = createXLSLFormatObj[i][j]
                  ? createXLSLFormatObj[i][j]
                  : null;
              }
            }
            aoa = aoa.map((array) => array.filter((value) => !!value));
            createXLSLFormatObj = aoa;
          }
          let ws_name = this.sheetNames[idx];

          let ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
          XLSX.utils.book_append_sheet(wb, ws, ws_name);
        }
      });
      XLSX.writeFile(wb, fileName);
    },
    exportExcel() {
      let createXLSLFormatObj = [];
      let newXlsHeader = [];
      let XLSX = require("xlsx");
      if (this.columns.length === 0) {
        return;
      }
      if (this.data.length === 0) {
        return;
      }

      this.columns.map((column) => {
        newXlsHeader.push(column.label);
      });

      createXLSLFormatObj.push(newXlsHeader);
      this.data.map((value) => {
        let innerRowData = [];
        this.columns.map((val) => {
          let fieldValue = value[val.field];
          if (val.field.split(".").length > 1) {
            fieldValue = this.getNestedValue(value, val.field);
          }
          if (val.dataFormat && typeof val.dataFormat === "function") {
            innerRowData.push(val.dataFormat(fieldValue));
          } else {
            innerRowData.push(fieldValue);
          }
        });
        createXLSLFormatObj.push(innerRowData);
      });

      let fileName = this.fileName + "." + this.fileType;

      let ws_name = this.sheetName;

      let wb = XLSX.utils.book_new(),
        ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
      XLSX.utils.book_append_sheet(wb, ws, ws_name);
      XLSX.writeFile(wb, fileName);
    },
    getNestedValue(object, string) {
      string = string.replace(/\[(\w+)\]/g, ".$1");
      string = string.replace(/^\./, "");
      let a = string.split(".");
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
  watch: {
    data: function (newVal, oldVal) {
      if (this.async && !this.loading) this.exportExcel();
    },
    dataSheets: {
      handler: function (newVal, oldVal) {
        if (this.async && !this.loading && this.dataSheets[0].length > 0) {
          this.book ? this.exportBook() : this.exportExcel();
        }
      },
      deep: true,
    },
  },
};
</script>
