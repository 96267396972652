import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Plugins
// import i18n from './plugins/i18n'
import axios from "./plugins/axios";
import api from "./plugins/api/api";
import { signatureInstance } from "./plugins/signature";
import validateService from "./plugins/validate";
import "./plugins/globalComponents";
import "flowbite/dist/flowbite";
import Antd from "ant-design-vue";
import vueMultiSelect from "vue-multi-select";
import IdleVue from "idle-vue";
import Vue2Filters from "vue2-filters";
//Charts
import VueApexCharts from "vue-apexcharts";

// CSS
import "./assets/styles/main.css";

// Date
import moment from "moment";
import baseClient from "./core/data/network/client/base_client";

// import Vuelidate from "vuelidate";

// Usamos para controlar la actividad del usuario en la app, cuando exista inactividad se cerrara la sesion
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 30 * 60000, // Tiempo de la sesion  (n * 60000) donde n es el total de minutos,
  startAtIdle: true,
});

Vue.config.productionTip = false;
Vue.use(axios, router, api);
Vue.use(api);
Vue.use(baseClient);
Vue.use(signatureInstance);
Vue.use(validateService);
Vue.use(Antd);
Vue.use(vueMultiSelect);
Vue.use(Vue2Filters);
//Apexchart
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// Vue.use(Vuelidate);

Vue.filter("formatDateVue", function (value) {
  if (value) {
    return moment(String(value))?.format("MM/DD/YYYY hh:mm");
  }
});

new Vue({
  store,
  router,
  // i18n,
  render: (h) => h(App),

  onIdle() {
    if (this.$router.currentRoute.path !== "/login")
      this.$router.push("/logout"); // se cierra la sesión despues de 30 minutos de inactividad
  },

  beforeCreate() {
    this.$store.dispatch("initStore");
  },
}).$mount("#app");
