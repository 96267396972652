const url = '/password/'
export default {
    sendPasswordCode: (signature, axios, email)=>{
        return axios.post(`${url}reset/?`, {email}, {
            headers: {
                'X-SFC-Signature': signature({email}),
            }
        })
        .then(response => ({data: response.data}))
        .catch(error =>  ({error:error.response.data}))
    },
    resendPasswordCode: (signature, axios, email)=>{
        return axios.post(`${url}code/resend/`, {email}, {
            headers: {
                'X-SFC-Signature': signature({email}),
            }
        })
        .then(response => ({data: response.data}))
        .catch(error => {return {error: error?.response?.data || 'Error'}})
    },
    changePassword(signature, axios, form){
        return axios.post(`${url}change/`, form, {
            headers: {
                'X-SFC-Signature': signature(form)
            }
        })
        .then(response => ({data: response.data}))
        .catch(error => ({error: error?.response?.data}))
    }
}