import { notification } from "ant-design-vue";

export default {
    currentVersion: null,
    intervalId: null,
    async checkVersion() {
        try {
            const response = await fetch("/vers.json");
            const data = await response.json();
            this.currentVersion = data.version;
            localStorage.setItem("appVersion", data.version);
            this.handleNotificationChecked();
        } catch (error) {
            console.error("Failed to load version:", error);
        }
    },
    async checkForUpdates() {
        try {
            const checkedClose = localStorage.getItem("appVersionChecked");
            if (checkedClose == "true") {
                this.stopUpdateCheck();
                return;
            };
            const response = await fetch("/vers.json");
            const data = await response.json();
            const storedVersion = localStorage.getItem("appVersion");
            if (!storedVersion) {
                await this.checkVersion();
            }
            if (storedVersion && storedVersion != data.version) {
                this.notifyUser();
            }
        } catch (error) {
            console.error("Failed to check for updates:", error);
        }
    },
    notifyUser() {
        const key = "notiAppVersion";
        notification.close(key);
        notification.open({
            onClick: this.reloadPage,
            onClose: this.handleNotificationChecked(true),
            message: "Nueva versión disponible",
            description:
                "Por favor, recarga la página para obtener la última versión.",
            placement: "bottomRight",
            duration: 60,
            key,
        });
    },
    handleNotificationChecked(checked = false) {
        localStorage.setItem("appVersionChecked", checked);
    },
    reloadPage() {
        window.location.reload();
    },
    startUpdateCheck() {
        this.checkVersion();
        this.intervalId = setInterval(() => this.checkForUpdates(), 60000);
    },
    stopUpdateCheck() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }
};