const url = "/tarifas/";
export default {
  /**
   * Service for retrieve all filters
   * @param {*} axios
   * @returns ALl filters and your type
   */
  getFilters: (axios) => {
    return axios
      .get(`${url}columns_for_filter`)
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
  /**
   * Service for retrieve report
   * @param {*} axios
   * @param {*} params
   * @returns
   */
  getDataForFilterFields: (axios, params) => {
    return axios
      .get(
        `${url}data_for_fields_filter/${params.slug_name}/${params.format_id}/`
      )
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },

  /**
   * Service for generate report
   * @param {*} axios
   * @param {*} query
   * @returns
   */
  generateReport: (axios, signature, query) => {
    return axios
      .post(`${url}generate_report/`, query, {
        headers: { "X-SFC-Signature": signature(query) },
      })
      .then((response) => {
        return { data: response.data };
      })
      .catch((error) => {
        return { error: error?.response?.data };
      });
  },
};
