export default [
  {
    path: "complaints/duplicate_complaints/management/",
    component: () =>
      import("@/views/dashboard/duplicate-complaints/Management"),
    meta: {
      title: "Quejas duplicadas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        { label: "Quejas duplicadas" },
      ],
    },
  },
  {
    path: "complaints/duplicate_complaints/management/details",
    name: "ComplaintsDetailView",
    props: true,
    component: () =>
      import("@/views/dashboard/duplicate-complaints/ComplaintsDetail"),
    meta: {
      title: "Ver quejas duplicadas",
      breadcrumb: [
        { label: "Inicio", link: "/" },
        {
          label: "Quejas duplicadas",
          link: "/dashboard/complaints/duplicate_complaints/management",
        },
        { label: "Ver detalle" },
      ],
    },
  },
];
