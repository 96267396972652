export default [
    {
        path: 'complaint/tracking',
        component: ()=>import('@/views/customer/complaint/Tracking.vue'),
        meta: {
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Seguimiento'}
            ]
        }
    },
    {
        path: 'complaint/tracking/detail/:complaint_id',
        component: ()=>import('@/views/customer/complaint/Detail.vue'),
        meta: {
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Seguimiento', link:'/customer/complaint/tracking/'},
                {label: 'Detalle',},
            ]
        }
    },
    {
        path: 'complaint/tracking/detail/:complaint_id/replica',
        component: ()=>import('@/views/customer/complaint/Replica.vue'),
        meta: {
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Seguimiento', link:'/customer/complaint/tracking'},
                {label: 'Detalle', },
                {label: 'Reabrir'},
            ]
        }
    },
    {
        path: 'complaint/tracking/detail/:complaint_id/escalate',
        component: ()=>import('@/views/customer/complaint/Replica.vue'),
        meta: {
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Interponer', link:'/customer/complaint/tracking'},
                {label: 'Detalle', },
                {label: 'Escalar'},
            ]
        }
        
    },
    {
        path: 'complaint/details/:complaint_id/anexos/:estado_cod/:annexesSize',
        component: ()=>import('@/views/dashboard/complaints/Anexos.vue'),
        meta: {
            title: 'Anexos queja',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Seguimiento', link:'/customer/complaint/tracking/'},
                {label: 'Detalle',},
                {label: 'Subir anexos'},
            ]
        }
    },
    {
        path: 'complaint/calification/:complaintCode',
        component: () => import('@/views/customer/complaint/Calification'),
        meta: {
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Seguimiento', link: '/customer/complaint/tracking'},
                {label: 'Detalle', },
                {label: 'Evalúa'}
            ]
        }
    },
    {
        path: 'complaint/registration',
        component: ()=>import('@/views/customer/complaint/Registration.vue'),
        props: true,
        meta: {
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Interponer', link:'/customer/complaint/complaintcheck'},
                {label: 'Formulario'}
            ]
        }
    },
    {
        path: 'complaint/complaintcheck',
        component: ()=>import('@/views/customer/complaint/Check.vue'),
        meta: {
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Interponer', link:'/customer/complaint/complaintcheck'},
            ]
        }
    },
    {
        path: 'complaint/message',
        name: 'customerComplaintMessage',
        component: ()=>import('@/views/customer/complaint/Message.vue'),
        meta: {
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Seguimiento', link:'/customer/complaint/tracking'},
                {label: 'Mensaje'}
            ]
        }
    },
]