export default (form, files, fileName = "file") => {
  let formData = new FormData();
  let entries = Object.entries(form);
  Object.keys(form).forEach((key) => {
    form[key] = form[key] + "";
  });
  entries.forEach((entry) => {
    formData.append(entry[0], entry[1]);
  });
  if (files)
    files.forEach((file, index) => {
      formData.append(`${fileName}-${index}`, file);
    });
  return formData;
};
