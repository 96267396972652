const url = '/person/'

export default {
    getDetailUser: (axios, sign, data, id) => {
        return axios.get(`${url}filter/${id}/`, data, {
            headers: {
                'X-SFC-Signature': sign(data)
            }
        })
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    getQueryPerson: (axios, query) => {
        return axios.get(`${url}filter/${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    },
    getQueryPersonHistory: (axios, query) => {
        return axios.get(`${url}history${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    }
}