const url = '/complaint_productivity/'

export default {
    /**
     * Service for list Companys
     * @param {*} query 
     * @param {*} axios 
     * @returns 
     */
    listReproductivity: (axios, query) => {
        return axios.get(`${url}${query}`)
        .then(response => {return {data: response.data}})
        .catch(error => {return {error: error?.response?.data}});
    }
}