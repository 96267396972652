<template>
<div class="flex flex-col w-full h-auto mt-5 items-center justify-center text-center">
    <div class="w-full px-12 py-5 mt-2 rounded-lg border-dashed border-2 border-gray-300" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <input type="file" :id="id" v-if="(fileList.length === 1 && single) == false"
        class="w-px h-px opacity-0 overflow-hidden absolute" 
        @change="onChange"
        ref="file" 
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv" />
    
        <label :for="id" class="block cursor-pointer" v-if="!(fileList.length === 1 && single)">
            <span class="py-2 px-5 my-3 inline-block rounded-full bg-blue-dark text-xs text-white"><b>{{buttonLabel}}</b></span>
            <div>
                <p class="text-sm text-gray-500"><b class="text-gray-400">archivos soportados</b><br>
                csv, xls, xlsx</p>
            </div>
        </label>

        <ul class="mt-4" v-if="fileList" v-cloak>
            <li class="text-sm p-1" v-for="(file, i) in fileList" :key="i">
                {{file.name}} - ({{(file.size / (1024*1024)).toFixed(2)}} MB) <button class="ml-2 text-red-500" type="button" @click="remove(file)" title="Remove file">Eliminar</button>
            </li>
        </ul>
    </div>
</div>
</template>
<script>
export default {
    props: ['label', 'buttonLabel', 'required', 'id', 'fileList', 'single'],
    data(){
        return {

        }
    },
    methods: {
        onChange() {
            let newFileList = [...this.fileList];
            let files = [...this.$refs.file.files];
            files.forEach((item)=>{
                if(item.type.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') || item.type.includes('application/vnd.ms-excel') || item.type.includes('csv') ){
                    newFileList.push(item);
                } else {
                    this.$notification.error({
                        message: 'Notificación',
                        description: 'El formato del archivo no es válido',
                    });
                }
            });
            this.$emit('files-handler', newFileList);
            this.$refs.file.value = "";
        },
        remove(file) {
            let newFileList = this.fileList.filter(f => f != file);
            this.$emit('files-handler', newFileList);
        },
        dragover(event) {
            event.preventDefault();
            // Add some visual fluff to show the user can drop its files
            if (!event.currentTarget.classList.contains('bg-blue-light')) {
                event.currentTarget.classList.remove('bg-gray-100');
                event.currentTarget.classList.add('bg-blue-light');
            }
        },
        dragleave(event) {
            // Clean up
            // event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-blue-light');
        },
        drop(event) {
            event.preventDefault();
            this.$refs.file.files = event.dataTransfer.files;
            this.onChange(); // Trigger the onChange event manually
            // Clean up
            // event.currentTarget.classList.add('bg-gray-100');
            event.currentTarget.classList.remove('bg-blue-light');
        }
    }
}
</script>