import HmacSHA256 from "crypto-js/hmac-sha256";

const signature = (data) => {
  const sign = process.env.VUE_APP_SIGN;
  let hash = null;
  if (!data) {
    return "No data to sign";
  }
  if (typeof data == "string") {
    hash = HmacSHA256(data, sign).toString();
  } else {
    hash = HmacSHA256(JSON.stringify(data), sign).toString();
  }

  let signature = hash.toUpperCase();
  return signature;
}

const signatureInstance = {
  install(Vue) {
    Vue.prototype.$signature = signature;
  },
};

export { signature, signatureInstance };
