import complaintRouter from './complaint.router'
import store from '../../../store'
export default [
    {
        path: '/customer',
        component: () => import('@/layouts/FullWidthLayout.vue'),
        meta: {
            requiresAuth: true,
            breadcrumb: [
                        {label: 'Inicio', link: '/'}
                    ]
        },
        beforeEnter: (to, from, next)=> {
            if(!store.getters['session/getRole']){
                next()
            } else {
                next('/dashboard')
            }
        }, 
        children: [
            {
                path: '',
                component: () => import('@/views/customer/Home.vue'),
                meta: {
                    breadcrumb: [
                        {label: 'Inicio', link: '/'}
                    ]
                }           
            },
            {
                path: 'profile',
                component: ()=>import('@/views/customer/Profile.vue'),
                meta: {
                    breadcrumb: [
                        {label: 'Inicio', link: '/'},
                        {label: 'Perfil'}
                    ]
                }
            },
            ...complaintRouter
        ]
    }
]