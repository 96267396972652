<template>
  <div
    class="fixed top-0 left-0 h-screen w-full flex justify-center items-center z-1000 bg-black bg-opacity-10"
  >
    <div
      class="w-11/12 py-7 px-5 rounded-xl shadow-xl bg-white border-2"
      :class="setSize"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "xl",
      validator: (val) =>
        ["sm", "md", "lg", "xl", "xl", "2xl", "3xl", "4xl", "5xl"].includes(
          val
        ),
    },
  },
  mounted() {
    document.body.style.position = "fixed";
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.width = "100%";
  },
  beforeDestroy() {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  },
  computed: {
    setSize: function () {
      switch (this.size) {
        case "sm":
          return "max-w-sm";
        case "lg":
          return "max-w-lg";
        case "md":
          return "max-w-md";
        case "xl":
          return "max-w-xl";
        case "2xl":
          return "max-w-2xl";
        case "3xl":
          return "max-w-3xl";
        case "4xl":
          return "max-w-4xl";
        case "5xl":
          return "max-w-5xl";

        default:
          return "max-w-xl";
      }
    },
  },
};
</script>

<style lang="css">
  .z-1000{
    z-index: 1000;
  }
</style>