import usersRouter from "./users.router";
import companiesRouter from "./companies.router";
import complaintsRouter from "./complaints.router";
import rolesRouter from "./roles.router";
import productivityRouter from "./productivity.router";
import managementRouter from "./management.router";
import duplicateComplaintsRouter from "./duplicate-complaints.router";
import directionsRouter from "./directions.router";
import alertsRouter from "./alerts.router";
import fcoRouter from "./fco.router";
import ratesRouter from "./rates.router";
import store from "../../../store";

export default [
  {
    path: "/dashboard",
    component: () => import("@/layouts/DashboardLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (store.getters["session/getRole"]) {
        next();
      } else {
        next("/customer");
      }
    },
    children: [
      {
        path: "",
        redirect: "home",
        component: () => import("@/views/dashboard/Home"),
        meta: {
          title: "Inicio",
          breadcrumb: [{ label: "Inicio", link: "/" }],
        },
      },
      {
        path: "home",
        component: () => import("@/views/dashboard/Home"),
        meta: {
          title: "Inicio",
          breadcrumb: [{ label: "Inicio", link: "/" }],
        },
      },
      ...alertsRouter,
      ...usersRouter,
      ...complaintsRouter,
      ...companiesRouter,
      ...rolesRouter,
      ...directionsRouter,
      ...productivityRouter,
      ...managementRouter,
      ...duplicateComplaintsRouter,
      ...fcoRouter,
      ...ratesRouter,
    ],
  },
];
