<template>
   <a-button 
    :type="atype" 
    :html-type="type" 
    shape="round" 
    :class="classObject"
    size="large" 
    :loading="loading"
    :disabled="disable">
      <b class="inline mx-4 font-bold text-xs">{{label}}</b>
    </a-button>
  <!-- <button :type="type" 
    class="block py-3 px-3 rounded-full border-2 text-xs transition"
    :class="classObject">
    <b>{{label}}</b>
  </button> -->
</template>

<script>
export default {
    props: ['type', 'atype', 'label', 'invert', 'danger', 'success', 'disable', 'loading'],
    computed: {
      classObject: function(){
        // return;
        let classes =  ['border-blue', 'bg-blue',  'text-white', 'hover:bg-blue-dark', 'hover:text-white', 'hover:border-blue-dark']
        if(this.invert){
          classes = ['border-blue', 'bg-white', 'text-blue', 'hover:bg-blue', 'hover:text-white']
        } else if(this.danger){
          classes = ['border-red-600', 'bg-white', 'text-red-600', 'hover:bg-red-600', 'hover:text-white']
        } else  if(this.success){
          classes = ['border-green-600', 'bg-green-600',  'text-white', 'hover:bg-green-700', 'hover:border-green-700', 'hover:text-white']
        } else if(this.disable){
          classes = ['border-gray-400', 'bg-gray-400',  'text-white', 'pointer-events-none']
        }
        return classes;
      }
    }
}
</script>