<template>
  <div class="w-full mt-5 font-bold slider">
    <span>
      {{ label }}: {{ value
      }}<a-icon v-if="isPercentage" type="percentage"></a-icon>
    </span>
    <a-slider
      :marks="marks"
      @change="sliderChange"
      :default-value="defaultValue"
      :max="max"
    />
  </div>
</template>
<script>
export default {
  props: {
    marks: {
      type: [],
    },
    max: {
      type: Number,
    },
    min: {
      type: Number,
    },
    defaultValue: {
      type: Number,
    },
    label: {
      type: String,
    },
    isPercentage: {
      type: Boolean,
      default: false,
    },
  },
  created(){
    if(this.defaultValue) this.value=this.defaultValue
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    sliderChange(event) {
      this.value = event;
      this.$emit("onChange", event);
    },
  },
};
</script>
<style>
.slider .ant-slider-track {
  height: 5px;
  background-color: #254dd1;
}
</style>
