export default [

    {
        path: 'companies',
        component: () => import('@/views/dashboard/companies/Managment'),
        meta: {
            title: 'Gestión de entidades vigiladas',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de entidades vigiladas' , link:'/dashboard/companies'},
            ]
        }
    },
    {
        path: 'companies/add',
        component: () => import('@/views/dashboard/companies/AddCompany'),
        meta: {
            title: 'Gestión de entidades vigiladas',
            breadcrumb: [
             {label: 'Inicio', link: '/'},
                {label: 'Gestión de entidades vigiladas' , link:'/dashboard/companies'},
                {label: 'Nueva entidad vigilada'}
            ]
        }
    },
    {
        path: 'companies/edit',
        component: () => import('@/views/dashboard/companies/EditCompany'),
        meta: {
            title: 'Gestión de entidades vigiladas',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Gestión de entidades vigiladas' , link:'/dashboard/companies'},
                {label: 'Editar entidad vigilada'}
            ]
        }
    },
]