export default [
    {
        path: 'productivity_report/managment',
        component: () => import('@/views/dashboard/productivity-report/Management'),
        meta: {
            title: 'Reporte de productividad',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Reporte de productividad', link:'/dashboard/productivity_report/managment'}
            ]
        }
    },
    {
        path: 'productivity_report/managment/detail/:complaint_id/:criterion_id',
        component: () => import('@/views/dashboard/productivity-report/ComplaintDetail'),
        meta: {
            title: 'Reporte de productividad',
            breadcrumb: [
                {label: 'Inicio', link: '/'},
                {label: 'Reporte de productividad', link:'/dashboard/productivity_report/managment'},
                {label: 'Ver detalle'}
            ]
        }
    },
];