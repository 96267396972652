import ErrorMessaje from "../../../error/error_messaje";

export default {
  get: (status, data) => {
    switch (status) {
      case "500":
        return {
          isSuccess: false,
          data: null,
          message: ErrorMessaje.internalServerError,
        };

      case "undefined":
        return {
          isSuccess: false,
          data: null,
          message: ErrorMessaje.invalidServerResponse,
        };

      case "success":
        return {
          isSuccess: true,
          data: data,
          message: "",
        };
    }
  },
};
